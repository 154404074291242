<template>
  <div id="FlightDelayReport" class="Content-Page">
    <div id="namePage" class="wrap-Main">
      <div class="box-S4">
        <div class="N-Page T-size-36">Delay Report</div>
      </div>
    </div>
    <div id="filltertReport" class="wrap-Main">
      <div class="box-S4">
        <div class="N-Page T-size-24">Filter</div>
      </div>

      <div class="box-S4">
        <div class="B-carddetail flex-between-center">
          <div class="box-S3 noPadding">
            <v-select
              class="hideMessage"
              :items="listyear"
              v-model="picker"
              label="Year"
              return-object
              @change="Getsummary()"
            ></v-select>
          </div>
          <div class="box-S3 noPadding">
            <v-select
              class="hideMessage"
              :items="itemsListCustomer"
              v-model="selectowner"
              item-text="o_name"
              item-value="o_id"
              label="Customer"
              return-object
              @change="Getsummary()"
            ></v-select>
          </div>
          <div class="box-S3 noPadding">
            <v-select
              class="hideMessage"
              :items="itemsListDeparture"
              v-model="selectDeparture"
              item-text="ap_name"
              item-value="ap_id"
              label="Departure From"
              return-object
              @change="departureFromChange"
            ></v-select>
          </div>
        </div>
      </div>
    </div>

    <div id="tableData" class="wrap-Main">
      <div class="box-S4">
        <v-data-table :headers="headers" :items="summarydata">
          <template v-slot:item.this_month="{ item }" class="text-xs-right">
            <div class="b-action">
              <v-btn
                class="theme-btn-even"
                @click="createpdf(item.this_month, item.monthname)"
              >
                <span>VIEW</span>
                <span class="I-arrrowR"></span>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>


<script>
import feathersClientUOA from "../plugins/feathers-client-uoa";
import {
  startOfDay,
  endOfDay,
  startOfMonth,
  endOfMonth,
  lastDayOfYear,
  format,
  getDate,
} from "date-fns";
import moment from "moment";
export default {
  data: () => ({
    headers: [
      { value: "monthname", text: "Month", sortable: true },
      { value: "totalflight", text: "Total Flight", sortable: true },
      { value: "totalflightdelay", text: "Total FlightDelay", sortable: true },
      {
        value: "totaldelayminute",
        text: "Total Delay(minute)",
        sortable: true,
      },
      // { value: "ac_seat_layout_url", text: "Seat Layout", sortable: true },
      //{ value: "totalfuel", text: "Total Fuel", sortable: true },
      //{ value: "location", text: "Path", sortable: true },
      { value: "this_month", text: "view", sortable: false },
    ],
    items: [],
    total: 0,
    loading: false,
    dialog: false,
    formModel: {},
    type: "month",
    picker: 0,
    dateSelect: new Date().toISOString().substr(0, 4),
    menu: false,
    // itemsListCompany: [],
    //Edit
    itemsListCustomer: [],
    // selectcompany:"",
    selectowner: "",
    ArrListDelayReport: [],
    DataDelayReport: [],
    //Add
    userdata: null,
    listyear: [],
    summarydata: [],
    monthname: "",
    itemsListDeparture: [],
    selectDeparture: {},
  }),
  computed: {},
  async mounted() {
    //Add
    await this.renderListDeparture();
    this.userdata = this.$CurrentUser.GetCurrentUser();

    // this.SelectListCompany();
    const currentYear = new Date().getFullYear();
    const range = (start, stop, step) =>
      Array.from(
        { length: (stop - start) / step + 1 },
        (_, i) => start + i * step
      );
    this.listyear = range(currentYear, currentYear - 10, -1);
    this.picker = this.listyear[0];
    await this.SelectListOwner();
    //this.SelectListPassengerSemi_Manifest(this.picker, this.selectowner.o_id);
    this.Getsummary();
  },

  methods: {
    async SelectListOwner() {
      try {
        const q = {};
        //Add
        if (this.userdata.r_id == 2) {
          q.o_id = this.userdata.o_id;
        }
        var res = await feathersClientUOA
          .service("customer")
          .find({ query: q });
        this.itemsListCustomer = res.data;
        this.selectowner = {
          o_id: res.data[0].o_id,
          o_name: res.data[0].o_name,
        };

        // res.data.forEach(element => {
        //   this.itemsListCompany.push(element.c_name);
        // });
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },
    //async SelectListPassengerSemi_Manifest(picker,company)
    //Edit
    async createpdf(month, monthname) {
      // alert(month)
      // alert(this.picker)
      this.monthname = monthname + " " + this.picker;
      await this.SelectListPassengerSemi_Manifest(month, this.picker);
      await this.DelayReport();

      //alert(this.monthname);
    },
    async SelectListPassengerSemi_Manifest(month, year) {
      this.ArrListDelayReport = [];
      this.DataDelayReport = [];
      try {
        var q = {};
        q.month = month;
        q.year = year;
        // q.company = this.selectowner.c_id;
        q.owner = this.selectowner.o_id;
        q.ap_id = this.selectDeparture.ap_id;
        //alert(JSON.stringify(q))

        var res = await feathersClientUOA
          .service("delayreportcommand")
          .find({ query: q });
        this.ArrListDelayReport = res[0].data;
        this.CountFilght = res[0].sum;
      } catch (error) {
        console.log(error);
      }

      var countfilghtdelayall = "";
      var countfilghtdelay1 = "";
      var countfilghtdelay2 = "";
      var countfilghtdelay3 = "";
      var caltime = 0;
      var caltime1 = 0;
      var caltime2 = 0;
      var caltime3 = 0;
      var summation = [];
      var summation1 = [];
      var summation2 = [];
      var summation3 = [];
      var totalAll = 0;
      var total1 = 0;
      var total2 = 0;
      var total3 = 0;

      countfilghtdelayall = this.ArrListDelayReport.filter(
        (x) => x.OverTime == true
      ).length;
      countfilghtdelay1 = this.ArrListDelayReport.filter(
        (x) => x.OverTime == true
      ).filter((x) => x.DelayCause == 1).length;
      countfilghtdelay2 = this.ArrListDelayReport.filter(
        (x) => x.OverTime == true
      ).filter((x) => x.DelayCause == 2).length;
      countfilghtdelay3 = this.ArrListDelayReport.filter(
        (x) => x.OverTime == true
      ).filter((x) => x.DelayCause == 3).length;
      caltime = this.ArrListDelayReport.filter((x) => x.OverTime == true);

      for (let i = 0; i < caltime.length; i++) {
        summation.push(caltime[i].Time);
      }
      const totalDurations = summation
        .slice(1)
        .reduce(
          (prev, cur) => moment.duration(cur).add(prev),
          moment.duration(summation[0])
        );

      // totalAll = moment.utc(totalDurations.asMilliseconds()).format("HH:mm");

      //------------------------------------------------------------------------------------//

      caltime1 = this.ArrListDelayReport.filter(
        (x) => x.OverTime == true
      ).filter((x) => x.DelayCause == 1);

      for (let i = 0; i < caltime1.length; i++) {
        summation1.push(caltime1[i].Time);
      }
      const totalDurations1 = summation1
        .slice(1)
        .reduce(
          (prev, cur) => moment.duration(cur).add(prev),
          moment.duration(summation1[0])
        );

      //total1 = moment.utc(totalDurations1.asMilliseconds()).format("HH:mm");

      //------------------------------------------------------------------------------------//

      caltime2 = this.ArrListDelayReport.filter(
        (x) => x.OverTime == true
      ).filter((x) => x.DelayCause == 2);

      for (let i = 0; i < caltime2.length; i++) {
        summation2.push(caltime2[i].Time);
      }
      const totalDurations2 = summation2
        .slice(1)
        .reduce(
          (prev, cur) => moment.duration(cur).add(prev),
          moment.duration(summation2[0])
        );

      //total2 = moment.utc(totalDurations2.asMilliseconds()).format("HH:mm");

      //------------------------------------------------------------------------------------//

      caltime3 = this.ArrListDelayReport.filter(
        (x) => x.OverTime == true
      ).filter((x) => x.DelayCause == 3);

      for (let i = 0; i < caltime3.length; i++) {
        summation3.push(caltime3[i].Time);
      }
      const totalDurations3 = summation3
        .slice(1)
        .reduce(
          (prev, cur) => moment.duration(cur).add(prev),
          moment.duration(summation3[0])
        );

      //total3 = moment.utc(totalDurations3.asMilliseconds()).format("HH:mm");

      //------------------------------------------------------------------------------------//

      let No_data = 1;

      this.ArrListDelayReport.forEach((element) => {
        if (No_data == 1) {
          this.DataDelayReport.push(
            [
              {
                text: "",
                style: '',
                border: [false, false, false, false],
              },
              {
                text: "",
                style: '',
                border: [false, false, false, false],
              },
              {
                text: "",
                style: '',
                border: [false, false, false, false],
              },
              {
                text: "",
                style: '',
                border: [false, false, false, false],
              },
              {
                text: "",
                style: '',
                border: [false, false, false, false],
              },
              {
                text: "",
                style: '',
                border: [false, false, false, false],
              },
              {
                text: "",
                style: '',
                border: [false, false, false, false],
              },
              {
                text: "",
                style: '',
                border: [false, false, false, false],
              },
              {
                text: "",
                style: '',
                border: [false, false, false, false],
              },
              { colSpan: 3, text: "Delay", arguments: "center" ,style: 'tableheader', },
              "",
              "",

              {
                text: "",
                style: '',
                border: [false, false, false, false],
              },
              {
                text: "",
                style: '',
                border: [false, false, false, false],
              },
            ],
            [
              { rowSpan: 2, text: "Date", arguments: "center",style: 'tableheader', },
              { text: "Flt.No.", arguments: "center" ,style: 'tableheader',},
              { rowSpan: 2, text: "Flt.No.", arguments: "center" ,style: 'tableheader',},
              { rowSpan: 2, text: "Aircraft", arguments: "center" ,style: 'tableheader',},
              { rowSpan: 2, text: "STD", alignment: "center",style: 'tableheader', },
              { colSpan: 3, text: "Actual", arguments: "center",style: 'tableheader', },
              "",
              "",
              { text: "Delay Time", arguments: "center",style: 'tableheader', },
              { text: "UOA", arguments: "center",style: 'tableheader', },
              { text: this.selectowner.o_name, arguments: "center" ,style: 'tableheader',},
              { text: "Other", arguments: "center" ,style: 'tableheader',},
              { rowSpan: 2, text: "Code", arguments: "center" ,style: 'tableheader',},
              { rowSpan: 2, text: "Reason for Delay", arguments: "center",style: 'tableheader', },
            ],
            [
              "",
              { text: "per month", arguments: "center",style: 'tableheader', },
              "",
              "",
              "",
              { text: "Start", arguments: "center" ,style: 'tableheader',},
              { text: "Taxi", arguments: "center" ,style: 'tableheader',},
              { text: "Take off", arguments: "center", fontSize: 5.6,style: 'tableheader', },
              { text: "minute", arguments: "center" ,style: 'tableheader',},
              { text: "minute", arguments: "center" ,style: 'tableheader',},
              { text: "minute", arguments: "center" ,style: 'tableheader',},
              { text: "minute", arguments: "center" ,style: 'tableheader',},
              "",
              "",
            ]
          );
        }

        var Delay = "";
        var DelayCause1 = "";
        var DelayCause2 = "";
        var DelayCause3 = "";
        if (element.OverTime == true) {
          if (element.OverTime == true) {
            Delay = { text: element.Time, color: "red" };
            totalAll += element.DelayTime;
          }
          if (element.DelayCause == null || element.DelayCause == "") {
            DelayCause1 = { text: "-" };
            DelayCause2 = { text: "-" };
            DelayCause3 = "-";
          } else if (element.DelayCause == "1") {
            DelayCause1 = { text: element.Time, color: "red" };
            DelayCause2 = { text: "-" };
            DelayCause3 = "-";
            total1 += element.DelayTime;
          } else if (element.DelayCause == "2") {
            DelayCause1 = { text: "-" };
            DelayCause2 = { text: element.Time, color: "red" };
            DelayCause3 = "-";
            total2 += element.DelayTime;
          } else if (element.DelayCause == "3") {
            DelayCause1 = { text: "-" };
            DelayCause2 = { text: "-" };
            DelayCause3 = { text: element.Time, color: "red" };
            total3 += element.DelayTime;
          }
        } else {
          Delay = "-";
          DelayCause1 = { text: "-" };
          DelayCause2 = { text: "-" };
          DelayCause3 = "-";
        }

        let FltNo = "";
        if (element.FilghtID != "-") {
          FltNo = this.selectDeparture.ap_name + "-" + element.FilghtID;
        } else {
          FltNo = element.FilghtID;
        }

        this.DataDelayReport.push([
          element.Date,
          element.Filght,
          { text: FltNo, fontSize: 5 },
          element.Aircraft,
          element.STD,

          element.Start,
          element.Taxi,
          element.TakeOff,

          Delay,
          DelayCause1,
          DelayCause2,
          DelayCause3,
          element.Code,
          element.Reason,
        ]);
        No_data++;
      });

      this.DataDelayReport.push(
        [
          { text: "Total flight no.", style: "tableHeader" },
          this.CountFilght[0].Allfilght,
          "",
          {
            text: "Flight delay no.",
            colSpan: 5,
            bold: true,
            fontSize: 6,
            alignment: "left",
          },
          "",
          "",
          "",
          "",
          countfilghtdelayall,
          countfilghtdelay1,
          countfilghtdelay2,
          countfilghtdelay3,
          { text: "", border: [false, false, false, false] },
          { text: "", border: [false, false, false, false] },
        ],
        [
          { text: "", border: [false, false, false, false] },
          { text: "", border: [false, false, false, false] },
          { text: "", border: [false, false, false, false] },
          {
            text: "Flight delay hour (hh:mm)",
            colSpan: 5,
            bold: true,
            fontSize: 6,
            alignment: "left",
          },
          "",
          "",
          "",
          "",
          this.secondsToHms(totalAll),
          this.secondsToHms(total1),
          this.secondsToHms(total2),
          this.secondsToHms(total3),
          { text: "", border: [false, false, false, false] },
          { text: "", border: [false, false, false, false] },
        ]
      );
    },
    async DelayReport() {
      // this.SelectListPassengerSemi_Manifest()
      var uoaimage = await this.$LogoService.GetLogoBaseurl64(1);
      if (this.selectowner != "") {
        var pdfMake = require("../../public/js/pdfmake");
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require("../../public/js/vfs_fonts");
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var q = {};
        q.o_id = this.selectowner.o_id;
        var res = await feathersClientUOA
          .service("customer")
          .find({ query: q });

        // var imageCustomer = res.data[0].o_image;
        // var logo = imageCustomer;
        var logo = "";

        var w = 0;
        var h = 0;
        var margin = 0;

        var resultLogo = await this.$LogoService.GetLogoBaseurl64(
          this.selectowner.o_id
        );
        //alert("LOGO : " + JSON.stringify(resultLogo));

        w = resultLogo.w;
        h = resultLogo.h;
        margin = -100;
        logo = resultLogo.logo;

        let note2_1 = "";
        let note2_2 = "";
        let note2_3 = "";
        let note2_4 = "";
        if (this.selectDeparture.ap_id == 1) {
          note2_1 =
            "Percentage of Helicopter Availabilities within 2 Hrs. from Take off time by UOA delayed reason";
          note2_2 = "100 % ( CPOC flt. = 27 Flt. / Delay = 0 Flt.)";

          note2_3 =
            "Percentage of Dispatch reliabilities within 15 Min. from Taxi time by UOA delayed reason";
          note2_4 = "100 % ( CPOC flt. = 27 Flt. / Delay = 0 Flt.)";
        }

        let revision = "";
        if(this.selectowner.o_id == 4){
          revision = "OPS-FM-71 Issue 02 Revision 00";
        }else{
          revision = "";
        }

        var docDefinition = {
          pageMargins: [30, 68, 30, 20],
          header: {
            margin: [0, 30, 0, 0],
            table: {
              widths: ["*", "auto", "*"],
              body: [
                [
                  {
                    width: uoaimage.w,
                    height: uoaimage.h,
                    alignment: "left",
                    margin: [15, 4, 0, 0],
                    image: uoaimage.logo,
                    border: [false, false, false, false],
                  },
                  {
                    // text: this.selectcompany.c_name+" Punctuality report in "+ format(new Date(this.picker), "MMMM yyyy"),
                    text:
                      this.selectowner.o_name +
                      " Punctuality report in " +
                      this.monthname,
                    style: "header",
                    alignment: "center",
                    decoration: "underline",
                    border: [false, false, false, false],
                  },
                  {
                    width: w,
                    height: h,
                    alignment: "right",
                    margin: [15, 0, 0, 0],
                    image: logo,
                    border: [false, false, false, false],
                  },
                ],
              ],
            },
          },

          content: [
            {
              style: "tableHeader",

              table: {
                // widths: [ 53, 31.5, 18, 27, 19, 18.3, 18.3, 21.5, 22, 22, 22, 22, 15, 100],
                widths: [
                  43, 30, 35, 27, 19, 18.3, 18.3, 21.5, 22, 22, 22, 22, 15, 100,
                ],
                headerRows:3,
                body: this.DataDelayReport,
              },
            },

            {
              unbreakable: true,
              fontSize: 6,
              layout: "noBorders",
              table: {
                widths: ["*"],
                body: [
                  [
                    {
                      text: "Note(1): Delay Code",
                      style: "subheader",
                    },
                  ],
                  [
                    {
                      table: {
                        widths: ["*", "auto", "*"],

                        body: [
                          [
                            {
                              text: "1- ATC",
                              fontSize: 6,
                              border: [false, false, false, false],
                            },
                            {
                              text: "6- Fuelling",
                              fontSize: 6,
                              border: [false, false, false, false],
                            },
                            {
                              text: "11- Late PSR",
                              fontSize: 6,
                              border: [false, false, false, false],
                            },
                          ],
                          [
                            {
                              text: "2- Weather",
                              fontSize: 6,
                              border: [false, false, false, false],
                            },
                            {
                              text: "7- Technical Problem and consequence from technical problem",
                              fontSize: 6,
                              border: [false, false, false, false],
                            },
                            {
                              text: "12- Late boarding due to passenger",
                              fontSize: 6,
                              border: [false, false, false, false],
                            },
                          ],
                          [
                            {
                              text: "3- Traffic at airport/ traffic offshore",
                              fontSize: 6,
                              border: [false, false, false, false],
                            },
                            {
                              text: "8- Flight Planning",
                              fontSize: 6,
                              border: [false, false, false, false],
                            },
                            {
                              text: "13- Other",
                              fontSize: 6,
                              border: [false, false, false, false],
                            },
                          ],
                          [
                            {
                              text: "4- Late manifest",
                              fontSize: 6,
                              border: [false, false, false, false],
                            },
                            {
                              text: "9- Late incoming of aircraft",
                              fontSize: 6,
                              border: [false, false, false, false],
                            },
                            {
                              text: "",
                              fontSize: 6,
                              border: [false, false, false, false],
                            },
                          ],

                          [
                            {
                              text: "5- Communication / Internet failure",
                              fontSize: 6,
                              border: [false, false, false, false],
                            },
                            {
                              text: "10- Late Crew",
                              fontSize: 6,
                              border: [false, false, false, false],
                            },
                            {
                              text: "",
                              fontSize: 6,
                              border: [false, false, false, false],
                            },
                          ],
                        ],
                      },
                    },
                  ],
                  [
                    {
                      text: "Note(2):",
                      style: "subheader",
                    },
                  ],
                  [
                    {
                      table: {
                        widths: [50, "auto", "*"],

                        body: [
                          [
                            {
                              text: "",
                              fontSize: 6,
                              border: [false, false, false, false],
                            },
                            {
                              text: note2_1,
                              fontSize: 6,
                              border: [false, false, false, false],
                              color: "red",
                            },
                            {
                              text: note2_2,
                              fontSize: 6,
                              border: [false, false, false, false],
                              alignment: "right",
                              color: "red",
                            },
                          ],
                          [
                            {
                              text: "",
                              fontSize: 6,
                              border: [false, false, false, false],
                            },
                            {
                              text: note2_3,
                              fontSize: 6,
                              border: [false, false, false, false],
                              color: "red",
                            },
                            {
                              text: note2_4,
                              fontSize: 6,
                              border: [false, false, false, false],
                              alignment: "right",
                              color: "red",
                            },
                          ],
                        ],
                      },
                    },
                  ],
                  [
                    {
                      text: "Prepare by: Flight UOA",
                      style: "subheader",
                    },
                  ],
                ],
              },
            },
          ],
          footer: function (currentPage, pageCount) {
            var footer = {
              text: revision,
              fontSize: 8,
              font: "Roboto",
              alignment: "right",
              margin: [0, 0, 20, 0],
            };
            return footer;
          },
          styles: {
            tableheader: {
              fillColor: '#ededed',
              bold: true
            },
            header: {
              fontSize: 12,
              bold: true,
              margin: [0, 0, 0, 10],
            },
            subheader: {
              fontSize: 8,
              bold: true,
              margin: [0, 10, 0, 5],
            },
            tableExample: {
              margin: [0, 5, 0, 15],
            },
            tableHeader: {
              bold: true,
              fontSize: 6,
              alignment: "center",
            },
          },
          defaultStyle: {
            // alignment: 'justify'
          },
        };

        pdfMake.createPdf(docDefinition).download("Delay_Report.pdf");

        this.DataDelayReport = [];
      }
    },
    async Getsummary() {
      const q = {};
      q.year = this.picker;
      if (this.userdata.r_id == "2") q.customerid = this.userdata.o_id;
      else q.customerid = this.selectowner.o_id;
      q.ap_id = this.selectDeparture.ap_id;
      var res = await feathersClientUOA
        .service("summarydelay")
        .find({ query: q });
      console.log(res);
      this.summarydata = res;
    },
    async renderListDeparture() {
      try {
        var data = await feathersClientUOA.service("mastercommand").find({});
        var airports = data[0].airport.filter((x) => x.ap_checkin == true);
        airports.forEach((element) => {
          this.itemsListDeparture.push(element);
        });
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
      await this.selectDepartureFrom();
    },
    async selectDepartureFrom() {
      try {
        let departureChange =
          await this.$LocalStorageService.getDepartureChange();
        if (departureChange == null) {
          this.selectDeparture = {
            ap_id: this.itemsListDeparture[0].ap_id,
            ap_name: this.itemsListDeparture[0].ap_name,
          };
        } else {
          let obj = JSON.parse(departureChange);
          this.selectDeparture = { ap_id: obj.ap_id, ap_name: obj.ap_name };
        }
      } catch (error) {
        console.log(
          "SelectListCustomer ไม่สามารถขอข้อมูลจาก server ได้ /nError : " +
            error
        );
      }
    },
    async departureFromChange(arg) {
      this.$LocalStorageService.setDepartureChange(
        this.selectDeparture.ap_id,
        this.selectDeparture.ap_name
      );
      await this.Getsummary();
    },

    secondsToHms(d) {
      d = Number(d);
      var h = Math.floor(d / 60);
      var m = Math.floor(d % 60);
      return (
        h.toString().padStart(2, "0") + ":" + m.toString().padStart(2, "0")
      );
    },
  },
};
</script> 


<style>
</style>